/* تحديث AppBar.css */
:root {
    --primary-color: rgb(77, 46, 101); /* لون من الشعار */
    --secondary-color: #789abc; /* لون مكمل */
    --neutral-color: #f4f4f4; /* لون محايد للخلفية */
    --accent-color: #707070; /* لون للأزرار أو النقاط البارزة */
    --main-font: 'Cairo', sans-serif;
}
/* الأساسيات */
.appbar-container {
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    font-family: var(--main-font);
    z-index: 1000;
  }
  
  .appbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  
  .logo img {
    height: 50px;
  }
  
  /* القائمة */
  .menu {
    display: flex;
    gap: 20px;
  }
  
  .menu ul {
    list-style: none;
    display: flex;
    flex-direction: row-reverse;
    margin: 0;
    padding: 0;
  }
  
  .menu li {
    margin: 0;
    font-weight: bold;
    font-family: var(--main-font);
    color: rgb(77, 46, 101); /* لون النص الأساسي */
    background-color: transparent; /* خلفية الأزرار */
    border-radius: 5px; /* زوايا مستديرة */
    text-align: center;
    transition: all 0.3s ease; /* تأثير التبديل */
  }
  .menu li:hover {
    transform: scale(1.05); /* تكبير بسيط عند التمرير */
    background-color: rgb(77, 46, 101);
    color: white;
  }
  
  .menu a {
    text-decoration: none;
    color: #333;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    transition: color 0.3s;
  }
  
  .menu a:hover {
    color: white;
  }
  
  /* رمز القائمة للموبايل */
  .menu-icon {
    display: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* للشاشات الصغيرة */
  @media (max-width: 768px) {
    .menu {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 100%;
      right: 0;
      background-color: white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      width: 200px;
      text-align: right;
      border-radius: 5px;
      padding: 10px 0;
    }
  
 /* القائمة عند فتحها */
.menu.open {
    display: flex;
    flex-direction: column;
    align-items: end;
    background-color: #fff; /* خلفية القائمة */
    border: 1px solid #ddd; /* حدود خفيفة */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* تأثير ظل */
    border-radius: 10px; /* زوايا مستديرة */
    padding: 10px 20px; /* مسافة داخلية */
    animation: slideIn 0.3s ease-in-out; /* حركة الدخول */
  }
  
  /* العناصر داخل القائمة */
  .menu.open ul {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-around;
    height: 300px;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  
  .menu.open li {
    list-style: none; /* إزالة النقاط */
    font-size: 18px;
    font-weight: bold;
    font-family: var(--main-font);
    color: rgb(77, 46, 101); /* لون النص الأساسي */
    background-color: transparent; /* خلفية الأزرار */
    border-radius: 5px; /* زوايا مستديرة */
    width: 100%;
    text-align: center;
    transition: all 0.3s ease; /* تأثير التبديل */
  }
  
  .menu.open a {
    text-decoration: none;
    width: 100%;

  }
  
  .menu.open li:hover {
    transform: scale(1.05); /* تكبير بسيط عند التمرير */
  }
  
  /* حركة الدخول للقائمة */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
    .menu-icon {
      display: block;
    }
  }
  
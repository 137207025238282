/* التصميم الأساسي */
.footer {
    background-color: #f9fafb;
    padding: 20px;
    text-align: center;
    font-family: var(--main-font);
    border-top: 2px solid #e0e0e0;
    padding-bottom: 80px;
}

/* الأقسام داخل الفوتر */
.footer section {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* العناوين */
.footer h2 {
    font-size: 20px;
    color: #333;
    border-bottom: 1px solid var(--primary-color);
    margin-bottom: 10px;
    font-weight: bold;
    width: auto;
}

/* التفاصيل */
.footer details {
    background: none;
    padding: 10px 15px;
    margin: 10px 0;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.footer details summary {
    font-size: 15px;
    font-weight: 500;
    color: black;
    cursor: pointer;
    list-style: none;
}

.footer details a {
    text-decoration: none;
    color: var(--primary-color);
    direction: rtl;
}

.footer details p {
    font-size: 0.95rem;
    color: #555;
    margin-top: 10px;
    line-height: 1.5;
    direction: rtl;
}

.footer details summary::marker {
    content: "";
}

.footer details summary::after {
    content: " ▾";
    font-size: 0.9rem;
    color: #888;
    margin-left: 5px;
}

.footer details[open] summary::after {
    content: " ▴";
}

/* TAREKSCODE */
#miniuDiv
{
    /* bottom: 0px; */
    /* position: fixed; */
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    display: flex;
    position: static;
    bottom: 0px;
    flex-direction: column;
    color: white;
    align-self: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 10px;
}
#design
{
    color: black;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-bottom: 0px;
    margin-top: 0px;
}
.hidden
{
    opacity: 0;
    transition: all 1s;
    filter: blur(5px);
    /* transform: translateY(-100%); */
}
.show
{
    opacity: 1;
    filter: blur(0);
    /* transform: translateY(0); */

}
.LinksForTarekDiv
{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 50%;
    margin-top: 5%;
}
.TarekLinksImages
{
    width: 100%;
}
.LinksForTarekDiv a
{
    justify-content: center;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
}

/* تحسين التصميم للشاشات الكبيرة */
@media (min-width: 1024px) {
    .footer {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* تقسيم الفوتر إلى 3 أعمدة */
        grid-gap: 20px;
        direction: rtl;
        padding: 40px 20px;
        padding-bottom: 80px;
        align-items: flex-start;
    }

    .footer .logo {
        grid-column: 1 / 2; /* الشعار في العمود الأول */
        text-align: center;
    }

    .footer section {
        margin-bottom: 0; /* إزالة الهوامش الزائدة */
        justify-content: flex-start;
        align-items: flex-start;
    }

    .footer h2 {
        font-size: 1.5rem;
        margin-bottom: 15px;
        direction: rtl;

    }

    .footer details summary {
        font-size: 1.1rem;
        direction: rtl;

    }
    .footer details{
        font-size: 1rem;
        direction: rtl;
        text-align: right;
        align-items: end;
    }
    .footer details p,
    .footer details a {
        font-size: 1rem;
        direction: rtl;

    }

    .footer details[open] {
        border-radius: 5px;
        direction: rtl;

    }

    .footer details summary::after {
        font-size: 1.2rem;
        direction: rtl;

    }
    #miniuDiv
{
    /* bottom: 0px; */
    /* position: fixed; */
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    display: flex;
    position: static;
    bottom: 0px;
    flex-direction: column;
    color: white;
    align-self: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 10px;
}
#design
{
    color: black;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-bottom: 0px;
    margin-top: 0px;
}
}

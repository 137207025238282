.countdown-timer {
    text-align: center;
    font-family: 'Cairo', sans-serif;
    margin: 20px auto;
    width: 90%;
}

/* شريط التقدم */
.progress-bar {
    width: 100%;
    height: 10px;
    border: 5px solid rgb(240, 240, 240);
    background-color: #eee;
    border-radius: 50px;
    overflow: hidden;
    margin-bottom: 10px;
    position: relative;
}

.progress {
    height: 80%;
    background-color: #ff4d4d; /* اللون الأحمر */
    transition: width 1s linear;
    border-radius: 50px;
    
}

.time-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 2px solid #ddd;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #f9f9f9;
}

.time-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 60px;
}

.time-value {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
}

.time-label {
    font-size: 0.9rem;
    color: #555;
    margin-top: 5px;
}

.colon {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin: 0 5px;
}

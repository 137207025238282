/* تحديث AppBar.css */


:root {
    --primary-color: rgb(77, 46, 101); /* لون من الشعار */
    --secondary-color: #789abc; /* لون مكمل */
    --neutral-color: #f4f4f4; /* لون محايد للخلفية */
    --accent-color: #707070; /* لون للأزرار أو النقاط البارزة */
    --main-font: 'Cairo', sans-serif;
}

.HomePage
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--main-font);
    padding: 3%;
    padding-top: 15%;
    direction: rtl;
}
.HomePage h1{
    font-size: 22px;
    text-align: right;
    font-family: var(--main-font);
    color: black;
}
.HomePage p{
    font-size: 18px;
}
.HomePage h2{
    font-size: 18px;
    font-weight: 500;
    direction: rtl;
}
.HomePage h3{
    font-size: 18px;
    font-weight: 800;
    direction: rtl;
    background-color: gold;
}
.HomePage h4{
    font-size: 20px;
    margin-top: 0px;
}

.image-gallery {
    margin: 20px auto;
    max-width: 100%;
}

  
/* الصورة الكبيرة */
.main-image-container {
    text-align: center;
    margin-bottom: 20px;
}

.main-image {
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* الصور المصغرة */
.thumbnail-container {
    display: flex;
    width: 100%;
    gap: 20px;
    overflow-x: auto;
    padding: 10px;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 10%;
}

.thumbnail {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 2px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s, border-color 0.3s;
}

.thumbnail:hover {
    transform: scale(1.1);
}
.under-image-price
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    width: 100%;

}
.under-image-price h2
{
    color: var(--primary-color);
    font-size: 24px;
}
.under-image-price h3{
    color: #707070;
    font-size: 18px;
    text-decoration:line-through;
}
.image-gallery-2
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.image-gallery-2 img
{
    width: 90%;
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.click-here-to-order-button
{
    background-color: red;
    color: white;
    border: none;
    font-family: var(--main-font);
    padding: 15px 30px;
    font-size: 1.2rem;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}


    /* الزر الثابت */
.fixed-button {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: red;
    color: white;
    border: none;
    font-family: var(--main-font);
    padding: 15px 30px;
    font-size: 1.2rem;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    animation: bounce 2s infinite;
    text-decoration: none;

}


/* تأثير الحركة */
@keyframes bounce {
    0%, 100% {
        transform: translate(-50%, 0);
    }
    50% {
        transform: translate(-50%, -10px);
    }
}



.fetures-list
{
    text-align: center;
    padding: 20px;
    direction: rtl;
}

.order-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
}
.inputs{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
    font-family: var(--main-font);
}
.order-form h2 {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: #333;
}

.inputs input {
    width: 90%;
    padding: 10px;
    font-size: 1rem;
    direction: rtl;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-family: var(--main-font);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}

.order-form input:focus {
    border-color: #007bff;
    outline: none;
}

.click-here-to-order-button {
    background-color: red;
    color: white;
    font-size: 1.2rem;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}




.quantity-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 15px 0;
    font-size: 1rem;
    color: #333;
}

.quantity-container label {
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 10px;
}

.quantity-button {
    background-color: red;
    color: white;
    font-size: 1.5rem;
    border: none;
    border-radius: 5px;
    padding: 5px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}


.quantity-input {
    width: 50px;
    text-align: center;
    font-size: 1.2rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: none;
    pointer-events: none;
}


/* زر الواتساب */
.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    background-color: #25d366; /* لون واتساب */
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* animation: bounce 2s infinite; */
}

.whatsapp-button a {
    color: white;
    font-size: 2rem;
    text-decoration: none;
}

.whatsapp-popup {
    position: absolute;
    bottom: 70px;
    right: -20px;
    background-color: #fff;
    color: #333;
    padding: 10px 15px;
    font-size: 0.9rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    animation: fadeInOut 5s ease ;
}
.body-container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

/* تأثير ظهور واختفاء البوب أب */
@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes bubble {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
/* شاشة الانتظار */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.9); /* تأثير تعتيم */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000; /* ضمان ظهورها فوق كل العناصر */
  }
  
  .loading-logo {
    width: 150px; /* حجم الشعار */
    animation: pulse 1.5s infinite; /* تأثير التكبير والتصغير */
  }
  
  /* تأثير التكبير والتصغير */
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  



/* تحسين تصميم الصفحة للشاشات الكبيرة */
@media (min-width: 1024px) {
    .HomePage {
        display: grid;
        grid-template-columns: 1fr 2fr; /* تقسيم الصفحة إلى عمودين */
        grid-gap: 40px;
        padding: 50px;
    padding-top: 5%;

        align-items: start;
        max-width: 1400px;
        margin: 0 auto; /* توسيط الصفحة */
    }
  
    .image-gallery {
        grid-column: 1 / 2; /* الصور في العمود الأول */
        justify-self: center;
        top: 20px; /* المسافة من أعلى الشاشة */
        align-self: start; /* لضمان أن تبدأ من أعلى الحاوية */
    }
    
  
    .under-image-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;
        padding: 0 20px;
    }
  
    .order-form {
        grid-column: 2 / 3; /* النموذج في العمود الثاني */
        max-width: 600px;
        margin: 0 auto;
        padding: 20px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
  
    .order-form .inputs {
        grid-template-columns: repeat(2, 1fr); /* حقول الإدخال تكون بجانب بعضها */
    }
  
    .image-gallery-2 {
        grid-column: 1 / 3; /* الصور الإضافية تمتد على العمودين */
        margin-top: 40px;
    }
  
    .fetures-list {
        grid-column: 1 / 3; /* قائمة المزايا تمتد على العمودين */
        padding: 20px;
        background-color: none
        ;
        border-radius: 10px;
    }
  
    .fixed-button {
        bottom: 20px; /* زيادة المسافة من الحافة السفلية */
        width: 250px; /* ضبط عرض الزر */
    }
  }
  

  
  
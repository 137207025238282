.confirmation-page {
    font-family: "Cairo", sans-serif;
    text-align: center;
    padding: 20px;
    direction: rtl;
  }
  
  .confirmation-header {
    background-color: rgb(77, 46, 101);
    color: white;
    padding: 20px 0;
    direction: rtl;

  }
  
  .confirmation-main {
    padding: 20px;
    background-color: #f9fafb;
    border-radius: 10px;
    margin: 20px auto;
    max-width: 600px;
    direction: rtl;

    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .confirmation-main h2 {
    color: rgb(77, 46, 101);
    margin-bottom: 20px;
    direction: rtl;

  }
  
  .order-summary-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    direction: rtl;

  }
  
  .order-summary-table td {
    padding: 10px 15px;
    direction: rtl;

    border-bottom: 1px solid #ddd;
  }
  
  .order-summary-table .label {
    text-align: right;
    font-weight: bold;
    color: #333;
    direction: rtl;

    width: 50%;
  }
  
  .order-summary-table .value {
    text-align: left;
    color: #555;
    direction: rtl;

    width: 50%;
  }
  
  .thank-you-message {
    font-size: 16px;
    color: #333;
    direction: rtl;

    margin-top: 20px;
  }
  
  .confirmation-footer {
    margin-top: 20px;
    font-size: 14px;
    direction: rtl;

    color: #707070;
  }

/* زر الواتساب */
.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    background-color: #25d366; /* لون واتساب */
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    direction: rtl;

    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* animation: bounce 2s infinite; */
}

.whatsapp-button a {
    color: white;
    font-size: 2rem;
    direction: rtl;

    text-decoration: none;
}

.whatsapp-popup {
    position: absolute;
    bottom: 70px;
    right: -20px;
    direction: rtl;

    background-color: #fff;
    color: #333;
    padding: 10px 15px;
    font-size: 0.9rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    animation: fadeInOut 5s ease ;
}

/* تأثير ظهور واختفاء البوب أب */
@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
  
.back-to-home {
    text-align: center;
    margin-top: 20px;
  }
  
  .back-home-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: rgb(77, 46, 101);
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .back-home-button:hover {
    background-color: rgb(60, 36, 80);
  }
  